/* body{
  font-family: aptos;
} */

div {
    margin-bottom: 15px;
  }
  .mdiv{
    border: 0.5px solid black;
    border-radius: 12px;
    width: 500px;
    padding: 10px 100px 10px 100px;
    margin: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  }
  
  form {
    /* max-width: 400px; */
    margin: 0 auto;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input,
  select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 10px;

  }
  
  button {
    background-color: rgb(95, 95, 95);
    color: white;
    padding: 10px 15px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  /* Additional styling for dynamic fields */
  #dynamicFieldsContainer {
    margin-top: 15px;
  }
  
  .dynamicField {
    display: flex;
    margin-bottom: 10px;
  }
  
  .dynamicField input {
    margin-left: 10px;
  }
  
  