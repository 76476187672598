#climescore{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* justify-content: space-between; */
    gap: 30px;
    /* border: 1px solid rgb(153, 151, 151); */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

  }

  #threeimgdiv{
    display: flex;
    padding: 5px;
    gap: 30px;
    margin: auto;
}

#threeimgdiv div{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 10px;
    padding: 10px;
    width: 33%;
}

#threeimgdiv img{
  width: 98%;
}

#threeimgdiv ul{
    /* padding: 20px; */
    margin: auto;
    line-height: 30px;
    width: 80%;
   text-align: left;
}

#hospityality{
  /* color: white; */
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;;
  text-align: left;
  padding: 20px;
  /* background-image: url("https://i.pinimg.com/originals/8f/c1/c5/8fc1c5a1a9454e0c7ab9f9da4bb625c8.gif"); */
  /* background-color: rgb(211, 240, 224); */
}
.hospityalitydiv{
  color: white;
  text-align: left;
  border-radius: 10px;
  padding: 20px;
  /* background-image: url("https://i.pinimg.com/originals/8f/c1/c5/8fc1c5a1a9454e0c7ab9f9da4bb625c8.gif"); */
  /* background-color: rgb(63, 63, 63); */
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  /* background-color: rgb(211, 240, 224); */
}

#hospityality h2{
  color: white;
}


.hospityalitydiv2{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 20px;
}

.hospityalitydiv2 p{
  line-height: 25px;
} 

.logos{
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}

.logos img{
  /* width: 70%; */
  height: 130px;
  border-radius: 10px;
  /* margin-left: 30px; */
}
.hdiv21{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin: auto;
}
.hdiv21 div{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: rgb(251, 253, 251);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.hdiv21 img{
  width: 90%;
  height: 200px;
  border-radius: 20px;
}

  #climescore div{
    width: 98%;
  
  }

  .cproduct, .tproduct{
    padding: 10px;
    display: flex;
    height: 300px;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  }
  
  .cproduct img{
    width: 40%;
    border-radius: 10px;
  }

  .tproduct img{
    width: 40%;
    border-radius: 10px;
  }

  .tproduct h4, .cproduct h4{
    padding: 5px 20px;
    text-align: left;
  }

  #Marketplacediv{
    padding: 10px;
      }

  #Marketplacediv h1{
    text-align: left;
  }

  #marketdiv1{
    display: flex;
    gap: 10px;
  }
  #marketdiv1 div{
    margin: auto;
    box-shadow: rgba(122, 122, 122, 0.6) 0px 2px 50px 4px;
    padding: 10px;
    border-radius: 5px;
  }

  #marketproducts {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 10px;
    background-color: #f5f5f5;
    gap: 10px;
}

.product-item {
    flex: 0 0 auto;
    width: 220px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.product-name {
    font-size: 1em;
    margin: 10px 0;
    color: #333;
}

.product-label {
    font-weight: bold;
    margin: 5px 0 2px;
    color: #555;
}

.product-price,
.product-description,
.product-rating {
    font-size: 0.9em;
    color: #666;
    margin: 2px 0 10px;
}

.product-price {
    color: #ff4500;
}





  
  #Newsdiv{
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    width: 98%;
    padding: 10px;
    background-color: rgb(248, 252, 248);
  }
  #Newsdiv h1{
    text-align: left;
  }

  #Newsdiv h5{
    margin-top: -16px;
    text-align: left;
    line-height: 20px;
    padding: 10px;
  }

  .newsnews{
    padding: 20px;
  }

  .newsnews p{
    padding: 30px;
  }

  #Newsdiv .cproduct {
  width: 95%;
  }

  #Newsdiv .tproduct {
    width: 95%;
      }

      html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        overflow-x: hidden;
      }
      