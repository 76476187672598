.add-client-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .client-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .client-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .password-toggle-button:hover {
    background-color: transparent;

  }
  .password-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .password-input-container input {
    flex: 1;
    padding-right: 50px; /* Ensure space for the button */
  }
  
  .password-toggle-button {
    position: absolute;
    right: 10px;
    bottom: 13px;
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
  }  

  .loginnote {
    background-color: #f0f8ff; /* Light blue background */
    border-left: 5px solid #0078d4; /* Accent border for emphasis */
    padding: 20px;
    border-radius: 8px;
    margin: 20px auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    font-family: 'Arial', sans-serif;
  }
  
  .loginnote p {
    font-size: 18px;
    color: #333;
    line-height: 1.5;
  }
  
  .loginnote a {
    display: inline-block;
    margin-top: 10px;
    font-size: 18px;
    color: #0078d4; /* Accent color for link */
    text-decoration: none;
    font-weight: bold;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
  }
  
  .loginnote a:hover {
    color: #005a9e; /* Darker blue on hover */
    border-bottom: 2px solid #005a9e; /* Underline effect */
  }
  