/* Add this CSS to your existing styles or create a new stylesheet */

td {
    position: relative;
  }
  
  input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
  }

  #yearSelect{
margin-right: 50%;   
 width: 20%;
  }
  
  .search-results {
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    z-index: 1;
  }
  
  .search-result-item {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-result-item:hover {
    background-color: #f0f0f0;
  }
  

  .carousel-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .carousel {
    flex-wrap: nowrap;
  }
  
  .carousel-item {
    flex: 0 0 auto;
    width: 100%; /* Each carousel item takes 50% of the container */
  }
  
  .carousel-item table {
    width: 100%;
    min-width: 100%; /* Ensure table takes full width */
  }
  
  .carousel-item:not(:first-child) {
    margin-left: 20px; /* Adjust margin as needed */
  }

  h3{
    cursor: pointer;
  }
  