.product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
  
  .product-card {
    box-sizing: border-box;
    border: 1px solid #ccc;
    background-color: rgb(236, 236, 248);
    padding: 16px;
    border-radius: 5px;
    text-align: center;
  }
  
  .product-card img {
    max-width: 100%;
    height: auto;
  }
  
  #mainmarket{
    display: flex;
    justify-content: space-evenly;
  }
 
  #mainmarket select,  #mainmarket input{
    width: 400px;
  }

