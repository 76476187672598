.maincontact{
    background-image: url('https://gai.co.in/_next/image/?url=http%3A%2F%2Fgai-backend.gailabs.com%2Fsites%2Fdefault%2Ffiles%2F2023-03%2Fcontact-us-hand-man-holding-mobile-smartphone-with-phone-email-chat-icon-cutomer-support-concept-gray-wide-banner%2520%25281%2529.jpg&w=3840&q=75');
    background-size: cover; /* This ensures the image covers the entire element */
    background-position: center; /* This centers the image */
    background-repeat: no-repeat; /* This prevents the image from repeating */
    height: 120vh; /* Adjust height as needed */
    width: 100%;
}

.maincontact {
    display: flex;
    flex-direction: column;
    align-items: left;
    /* margin: 20px; */
    padding: 50px;
    /* color: white; */
    /* background-color: #f7f7f7; */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  form{
    text-align: left;
    /* border: 1px solid red; */
    margin-left: 0px;
  }
  
  .maincontact h1 {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .contactform {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
  }
  
  .contactform label {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .contactform input,
  .contactform select {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contactform button {
    margin-top: 20px;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .contactform button:hover {
    background-color: #45a049;
  }
  