.navbar-container {
    display: flex;
    justify-content: space-around;
    background-color: rgb(95, 95, 95);
    padding: 10px;
    height: 40px;
    text-align: center;
  }
  
  .nav-link {
    text-decoration: none;
    color: white;
    margin-top: -10px;
  }
  
  .navh3{
    color: white;
    margin-top: 10px;
  }

  a{
    color: black;
    text-decoration: none;
  }
  
