body{
    font-family: Inter, sans-serif;
}


#Mainnavbar #logoimg{
    border-radius: 10px;
    width: 16%;
}

.btndiv{
    text-align: right;
    padding: 10px 80px;
    font-size: 30px;
}
.btndiv button {
    font-size: 15px;
}

.navdiv2{
    display: flex;
    justify-content: space-between;
    text-align: center;
    height: 60px;
    background-color: rgb(221, 204, 184);
    padding: 3px 10px 3px 10px;
}

.navdiv3{
    display: flex;
    gap: 30px;
}

