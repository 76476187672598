/* Footer.css */
.footer {
    margin-top: 40px;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px;
    /* position: fixed; */
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  