.news-container {
    max-width: 75%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.news-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    text-transform: capitalize;
}

.news-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
}

.news-content {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
    text-align: left;
    white-space: pre-wrap;
}
