.news-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .news-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
  
  .news-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  .news-title {
    font-size: 1.25rem;
    margin-bottom: 8px;
  }
  
  .news-content {
    font-size: 1rem;
    color: #555;
  }
  